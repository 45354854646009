import React from 'react'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import UserForm from './ProfilePage/UserForm'

export default function ProfilePage() {
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <UserForm/>
        </GridItem>
      </GridContainer>
    </div>
  )
}
