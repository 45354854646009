import { combineReducers, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction'
import { sessionReducer } from './reducers/sessionReducer'
import { accountReducer } from './reducers/accountReducer'

const store = createStore(
  combineReducers({
    session: sessionReducer,
    account: accountReducer,
  }),
  composeWithDevTools(
    // applyMiddleware(...middleware),
    // other store enhancers if any
  ),
)
export default store
