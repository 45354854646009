export const types = {
  SET_SESSION: 'SET_SESSION',
  REFRESH_SESSION: 'REFRESH_SESSION',
}

export function setSession(session) {
  return {
    type: types.SET_SESSION,
    payload: {
      session,
    },
  }
}

export function refreshSession(successCallback, failCallback) {
  if (typeof successCallback !== 'function') {
    throw new Error('Success callback parameter must be a function')
  }
  if (typeof failCallback !== 'function') {
    throw new Error('Fail callback parameter must be a function')
  }
  return {
    type: types.REFRESH_SESSION,
    payload: {
      successCallback,
      failCallback,
    },
  }
}
