import { types } from 'store/actions/accountActions'

export function accountReducer(state = {}, action) {
  switch (action.type) {
    case types.SET_ACCOUNT:
      return Object.assign({}, state, {
        instance: action.payload.account,
      })
    default:
      return state
  }
}
