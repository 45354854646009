import React, { useState, useEffect } from "react";
// Components
import ConsumeCard from "../../components/ConsumeCard/ConsumeCard";
// Grid system
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import { useSelector } from "react-redux";
import getApiUrl from "utils/getApiUrl";
import Axios from "axios";
import { Collapse } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import PlanCard from "components/PlanCard/PlanCard";

export default function DashboardEtlSpark() {
  const account = useSelector((store) => store.account.instance);

  const [plan, setPlan] = useState();
  const [plansList, setPlansList] = useState([]);
  const [warning, setWarning] = useState(null);

  const PLANS_URL = `${getApiUrl()}/internal/plans`;

  useEffect(() => {
    if (!account) {
      return;
    }
    const plans = account.rawData.plans;
    if (!plans) {
      return;
    }
    setPlan(plans.filter((plan) => plan.product_id === 2)[0]);
  }, [account]);

  useEffect(() => {
    getPlans();
  }, []);

  async function getPlans() {
    try {
      return Axios.get(`${PLANS_URL}`)
        .then((response) => response.data)
        .then((data) => {
          setPlansList(data.data.filter((data) => data.product_id === 2));
        })
        .catch((e) => console.log(e));
    } catch (error) {
      return setWarning(error.message);
    }
  }
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6} lg={5}>
          <Collapse in={!!warning}>
            <Alert severity="warning">{warning}</Alert>
          </Collapse>
          <PlanCard
            name="Plan ETL Spark"
            plan={plan}
            setPlan={(data) => setPlan(data)}
            headerColor="warning"
            plansList={plansList}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6} lg={5}>
          <ConsumeCard />
        </GridItem>
      </GridContainer>
    </div>
  );
}
