import React, { useEffect } from 'react'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import Collapse from '@material-ui/core/Collapse'
import Alert from '@material-ui/lab/Alert'
// @material-ui/icons
import PeopleIcon from '@material-ui/icons/People'
import Dvr from '@material-ui/icons/Dvr'
import Close from '@material-ui/icons/Close'
// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Button from 'components/CustomButtons/Button.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardIcon from 'components/Card/CardIcon.js'
import CardHeader from 'components/Card/CardHeader.js'
import ReactTable from 'components/ReactTable/ReactTable.js'

import User from 'classes/User'

import { cardTitle } from 'assets/jss/material-dashboard-pro-react.js'

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
  },
}

const useStyles = makeStyles(styles)

function SelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
  return (
    <select
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined)
      }}
    >
      <option value="">All</option>
      <option value={true}>Admin</option>
      <option value={false}>Non admin</option>
    </select>
  )
}

function filterIsAdmin(rows, id, filterValue) {
  return rows.filter(row => {
    const rowValue = row.values[id]
    return rowValue.props.checked.toString() === filterValue
  })
}

export default function Users() {
  const [data, setData] = React.useState([])
  const [error, setError] = React.useState(null)
  const classes = useStyles()

  useEffect(() => {
    User.getUsers()
      .then(users => {
        setData(users.map((user, key) => {
          return {
            id: key,
            first_name: user.firstName,
            last_name: user.lastName,
            email: user.email,
            company: user.company,
            admin: <input
              type="checkbox"
              checked={user.isAdmin}
              disabled
            />,
            comment: user.comment,
            actions: (
              // we've added some custom button actions
              <div className="actions-right">
                {/* use this button to add a edit kind of action */}
                <Button
                  justIcon
                  round
                  simple
                  color="warning"
                  className="edit"
                >
                  <Dvr/>
                </Button>{' '}
                {/* use this button to remove the data row */}
                <Button
                  justIcon
                  round
                  simple
                  color="danger"
                  className="remove"
                >
                  <Close/>
                </Button>{' '}
              </div>
            ),
          }
        }))
      })
      .catch((error) => {
        console.error(error)
        if (error.response) {
          setError(error.response.data.message)
        } else {
          setError(error.message)
        }
      })
  }, [])

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Collapse in={!!error}>
          <Alert severity="error">{error}</Alert>
        </Collapse>
        <Card>
          <CardHeader
            color="primary"
            icon
          >
            <CardIcon color="primary">
              <PeopleIcon/>
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Users</h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              columns={[
                {
                  Header: 'First Name',
                  accessor: 'first_name',
                },
                {
                  Header: 'Last Name',
                  accessor: 'last_name',
                },
                {
                  Header: 'Email',
                  accessor: 'email',
                },
                {
                  Header: 'Company',
                  accessor: 'company',
                },
                {
                  Header: 'Admin',
                  accessor: 'admin',
                  Filter: SelectColumnFilter,
                  filter: filterIsAdmin,
                },
                {
                  Header: 'Comment',
                  accessor: 'comment',
                },
                {
                  Header: 'Actions',
                  accessor: 'actions',
                },
              ]}
              data={data}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  )
}
