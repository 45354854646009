import axios from 'axios'
import getApiUrl from 'utils/getApiUrl'

const LOGIN_URL = `${getApiUrl()}/internal/account/login`
const CONNECT_URL = `${getApiUrl()}/internal/auth`

export default class Session {
  #data
  #refreshCallback

  constructor(data) {
    this.#data = data
  }

  get rawData() {
    return this.#data
  }

  get refreshToken() {
    return this.#data.refresh_token
  }

  get accessToken() {
    return this.#data.access_token
  }

  setRefreshCallback(callback) {
    if (typeof callback !== 'function') {
      throw new Error('Callback parameter must be a function')
    }
    this.#refreshCallback = callback
  }

  resetRefreshCallback() {
    this.#refreshCallback = undefined
  }

  async refreshAccess(callback = undefined) {
    return axios.post(CONNECT_URL, {
      refresh_token: this.refreshToken,
    })
      .then(response => response.data)
      .then(data => {
        this.#data.access_token = data.data.access_token

        if (this.#refreshCallback) {
          this.#refreshCallback(this)
        } else if (callback) {
          if (typeof callback !== 'function') {
            throw new Error('Callback parameter must be a function')
          }
          callback(this)
        }

        return this
      })
  }

  save() {
    localStorage.setItem('refresh_token', this.refreshToken)
  }

  static logout() {
    localStorage.removeItem('refresh_token')
  }

  static async restore(refreshCallback = undefined) {
    return new Promise((resolve, reject) => {
      const refreshToken = localStorage.getItem('refresh_token')
      if (!refreshToken) {
        throw new Error('No refresh token found')
      }
      new Session({ refresh_token: refreshToken }).refreshAccess(refreshCallback)
        .then(session => resolve(session))
        .catch(error => {
          localStorage.removeItem('refresh_token')
          reject(error)
        })
    })
  }

  static async login(email, password) {
    return axios.post(LOGIN_URL, {
      email,
      password,
    })
      .then(response => response.data)
      .then(data => new Session(data.data))
  }
}
