import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// Components (style)
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import Card from "components/Card/Card";
import CardFooter from "components/Card/CardFooter";
import Collapse from "@material-ui/core/Collapse";
// Style
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-dashboard-pro-react/views/loginPageStyle";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@material-ui/core";

export default function PlanCard(props) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const [isEdit, setIsEdit] = useState(false);
  const [plan, setPlan] = useState();

  useEffect(() => {
    if (!props.plan) {
      return;
    }
    setPlan(props.plan);
  }, [props.plan]);

  const handleChange = (event) => {
    console.log("value input", event.target.value);
    setPlan(event.target.value);
  };

  const handleSubscribe = () => {
    console.log("state child", plan);
    props.setPlan(plan);
  };

  return (
    <Card>
      <CardHeader color={props.headerColor || "danger"}>
        <h3 className={classes.cardTitle}>{props.name}</h3>
      </CardHeader>
      <CardBody>
        <h1 className={classes.textCenter}>
          {props.plan ? props.plan.name : "FREE"}
        </h1>
        <Collapse in={isEdit}>
          <div>
            <h3 style={{ fontWeight: 400 }}>Change plan : </h3>
            <FormControl style={{ minWidth: 300, marginRight: 20 }}>
              <InputLabel id="plan-label">Plan</InputLabel>
              <Select
                labelId="plan-label"
                id="plan"
                value={plan}
                onChange={handleChange}
              >
                {props.plansList.map((plan, index) => {
                  return (
                    <MenuItem value={plan} key={index}>
                      {plan.name}
                    </MenuItem>
                  );
                })}
                {/* <MenuItem value="FREE">FREE</MenuItem>
                    <MenuItem value="France Standard">France standard</MenuItem>
                    <MenuItem value="France Premium">France premium</MenuItem>
                    <MenuItem value="International">International</MenuItem> */}
              </Select>
            </FormControl>
            {props.plan && props.plan !== props.plansList[0] ? (
              <>
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ marginTop: 12, marginRight: 20 }}
                  onClick={handleSubscribe}
                >
                  Update Plan
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  style={{ marginTop: 12 }}
                  onClick={() => props.setPlan(props.plansList[0])}
                >
                  Unsubscribe
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ marginTop: 12 }}
                  onClick={handleSubscribe}
                >
                  Subscribe
                </Button>
              </>
            )}
          </div>
        </Collapse>
      </CardBody>
      <CardFooter style={{ textAlign: "right", display: "block" }}>
        <h4>
          <Link
            to="#"
            onClick={() => setIsEdit(!isEdit)}
            style={{ color: "#6495ED" }}
          >
            {isEdit ? "Details 🡱" : "Details 🡳"}
          </Link>
        </h4>
      </CardFooter>
    </Card>
  );
}
