/**
 * Get error message from Axios error
 * @param {object} error - Error object from Axios function
 * @return {string} message - Extracted error message
 */
export default function getAxiosError(error) {
  if (error.response) {
    return error.response.data.message
  } else {
    return error.message
  }
}
