import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";
// @material-ui/icons
import ExitToApp from "@material-ui/icons/ExitToApp";
import Notifications from "@material-ui/icons/Notifications";
// core components
import Button from "components/CustomButtons/Button.js";
// Other imports
import { logout } from "modules/auth";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
// import { Manager, Target, Popper } from "react-popper";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const [openNotification, setOpenNotification] = React.useState(null);
  const handleClickNotification = (event) => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };
  const handleCloseNotification = () => {
    setOpenNotification(null);
  };
  // const [openProfile, setOpenProfile] = React.useState(null);
  // const handleClickProfile = event => {
  //   if (openProfile && openProfile.contains(event.target)) {
  //     setOpenProfile(null)
  //   } else {
  //     setOpenProfile(event.currentTarget)
  //   }
  // }
  // const handleCloseProfile = () => {
  //   setOpenProfile(null)
  // }
  const classes = useStyles();
  const { rtlActive } = props;
  // const searchButton =
  //   classes.top +
  //   ' ' +
  //   classes.searchButton +
  //   ' ' +
  //   classNames({
  //     [classes.searchRTL]: rtlActive,
  //   })
  // const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover, {
  //   [classes.dropdownItemRTL]: rtlActive,
  // })
  const wrapper = classNames({
    [classes.wrapperRTL]: rtlActive,
  });
  const managerClasses = classNames({
    [classes.managerClasses]: true,
  });
  return (
    <div className={wrapper}>
      <div className={managerClasses}>
        <Button
          color="transparent"
          justIcon
          aria-label="Notifications"
          aria-owns={openNotification ? "notification-menu-list" : null}
          aria-haspopup="true"
          onClick={handleClickNotification}
          className={classes.buttonLink}
          muiClasses={{
            label: "",
          }}
        >
          <Notifications
            className={classes.headerLinksSvg + " " + classes.links}
          />
          {/*<span className={classes.notifications}>2</span>*/}
          <Hidden mdUp implementation="css">
            <span
              onClick={handleClickNotification}
              className={classes.linkText}
            >
              Notification
            </span>
          </Hidden>
        </Button>
        <Popper
          open={Boolean(openNotification)}
          anchorEl={openNotification}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !openNotification,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true,
          })}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="notification-menu-list"
              style={{ transformOrigin: "0 0 0" }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleCloseNotification}>
                  <MenuList role="menu">
                    {/*<MenuItem*/}
                    {/*  onClick={handleCloseNotification}*/}
                    {/*  className={dropdownItem}*/}
                    {/*>*/}
                    {/*  Mike John responded to your email*/}
                    {/*</MenuItem>*/}
                    {/*<MenuItem*/}
                    {/*  onClick={handleCloseNotification}*/}
                    {/*  className={dropdownItem}*/}
                    {/*>*/}
                    {/*  You have 5 new tasks*/}
                    {/*</MenuItem>*/}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>

      {/*<div className={managerClasses}>*/}
      {/*  <Button*/}
      {/*    color="transparent"*/}
      {/*    aria-label="Person"*/}
      {/*    justIcon*/}
      {/*    aria-owns={openProfile ? 'profile-menu-list' : null}*/}
      {/*    aria-haspopup="true"*/}
      {/*    onClick={handleClickProfile}*/}
      {/*    className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}*/}
      {/*    muiClasses={{*/}
      {/*      label: '',*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <Person*/}
      {/*      className={*/}
      {/*        classes.headerLinksSvg +*/}
      {/*        ' ' +*/}
      {/*        (*/}
      {/*          classes.links*/}
      {/*        )*/}
      {/*      }*/}
      {/*    />*/}
      {/*    <Hidden mdUp implementation="css">*/}
      {/*      <span onClick={handleClickProfile} className={classes.linkText}>*/}
      {/*        Profile*/}
      {/*      </span>*/}
      {/*    </Hidden>*/}
      {/*  </Button>*/}
      {/*  <Popper*/}
      {/*    open={Boolean(openProfile)}*/}
      {/*    anchorEl={openProfile}*/}
      {/*    transition*/}
      {/*    disablePortal*/}
      {/*    placement="bottom"*/}
      {/*    className={classNames({*/}
      {/*      [classes.popperClose]: !openProfile,*/}
      {/*      [classes.popperResponsive]: true,*/}
      {/*      [classes.popperNav]: true,*/}
      {/*    })}*/}
      {/*  >*/}
      {/*    {({ TransitionProps }) => (*/}
      {/*      <Grow*/}
      {/*        {...TransitionProps}*/}
      {/*        id="profile-menu-list"*/}
      {/*        style={{ transformOrigin: '0 0 0' }}*/}
      {/*      >*/}
      {/*        <Paper className={classes.dropdown}>*/}
      {/*          <ClickAwayListener onClickAway={handleCloseProfile}>*/}
      {/*            <MenuList role="menu">*/}
      {/*              /!*<MenuItem*!/*/}
      {/*              /!*  onClick={handleCloseProfile}*!/*/}
      {/*              /!*  className={dropdownItem}*!/*/}
      {/*              /!*>*!/*/}
      {/*              /!*  Settings*!/*/}
      {/*              /!*</MenuItem>*!/*/}
      {/*              /!*<Divider light/>*!/*/}
      {/*              <MenuItem*/}
      {/*                onClick={logout}*/}
      {/*                className={dropdownItem}*/}
      {/*              >*/}
      {/*                Log out*/}
      {/*              </MenuItem>*/}
      {/*            </MenuList>*/}
      {/*          </ClickAwayListener>*/}
      {/*        </Paper>*/}
      {/*      </Grow>*/}
      {/*    )}*/}
      {/*  </Popper>*/}
      {/*</div>*/}
      <div className={managerClasses}>
        <Button
          color="transparent"
          justIcon
          onClick={() => logout()}
          className={classes.buttonLink}
          muiClasses={{
            label: "",
          }}
        >
          <ExitToApp
            className={[classes.headerLinksSvg, classes.links].join(" ")}
          />
        </Button>
      </div>
    </div>
  );
}

HeaderLinks.propTypes = {
  rtlActive: PropTypes.bool,
};
