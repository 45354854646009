import React, { useState, useEffect } from "react";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import TokenCard from "../../components/TokenCard/TokenCard";
import { useSelector } from "react-redux";
import getApiUrl from "utils/getApiUrl";
import axios from "axios";
import { Collapse } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import PlanCard from "components/PlanCard/PlanCard";
import User from "classes/User";

const ACCOUNT_URL = `${getApiUrl()}/internal/account`;

export default function Dashboard() {
  const account = useSelector((store) => store.account.instance);

  const [plan, setPlan] = useState();
  const [plansList, setPlansList] = useState([]);
  const [warning, setWarning] = useState(null);

  const PLANS_URL = `${getApiUrl()}/internal/plans`;

  useEffect(() => {
    if (!account) return;
    const plans = account.rawData.plans;
    if (!plans) {
      return;
    }
    setPlan(plans.filter((plan) => plan.product_id === 1)[0]);
  }, [account]);

  useEffect(() => {
    if (!plan) return;
    User.subscribe(plan.id, account.rawData.email, account.rawData.id);
  }, [plan]);

  useEffect(() => {
    getPlans();
  }, []);

  async function getPlans() {
    try {
      return axios
        .get(`${PLANS_URL}`)
        .then((response) => response.data)
        .then((data) => {
          setPlansList(data.data.filter((data) => data.product_id === 1));
        })
        .catch((e) => console.log(e));
    } catch (error) {
      return setWarning(error.message);
    }
  }

  return (
    <>
      {console.log("parent plan", plan)}
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6} lg={5}>
            <Collapse in={!!warning}>
              <Alert severity="warning">{warning}</Alert>
            </Collapse>
            <PlanCard
              name={"Plan API Company"}
              plan={plan}
              setPlan={setPlan}
              headerColor="info"
              plansList={plansList}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={5}>
            <TokenCard />
          </GridItem>
        </GridContainer>
      </div>
    </>
  );
}
