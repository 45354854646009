import React, { useCallback, useEffect, useState } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setSession } from 'store/actions/sessionActions'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import InputAdornment from '@material-ui/core/InputAdornment'
import Icon from '@material-ui/core/Icon'
import Collapse from '@material-ui/core/Collapse'
import CircularProgress from '@material-ui/core/CircularProgress'
import Alert from '@material-ui/lab/Alert'
// @material-ui/icons
import Email from '@material-ui/icons/Email'
// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import CustomInput from 'components/CustomInput/CustomInput.js'
import Button from 'components/CustomButtons/Button.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardFooter from 'components/Card/CardFooter.js'

import { roseColor } from 'assets/jss/material-dashboard-pro-react.js'
import styles from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle.js'

// Classes
import Session from 'classes/Session'
import Account from 'classes/Account'
import { setAccount } from '../../store/actions/accountActions'

const useStyles = makeStyles(styles)

export default function LoginPage() {
  const dispatch = useDispatch()
  const history = useHistory()
  const classes = useStyles()

  const [cardAnimation, setCardAnimation] = useState('cardHidden')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const login = useCallback((email, password) => {
    setError(null)
    setLoading(true)
    Session.login(email, password)
      .then(session => {
        session.setRefreshCallback((session) => {
          axios.defaults.headers.common['Authorization'] = `Bearer ${session.accessToken}`
        })
        session.save()
        return session
      })
      .then(session => session.refreshAccess())
      .then(session => dispatch(setSession(session)))
      .then(() => Account.getAccount())
      .then(account => {
        dispatch(setAccount(account))
        history.push('/admin/dashboard')
      })
      .catch(error => {
        console.error(error)
        if (error.response) {
          setError(error.response.data.message)
        } else {
          setError(error.message)
        }
      })
      .finally(() => setLoading(false))
  }, [dispatch, history])

  useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation('')
    }, 700)
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id)
    }
  })

  useEffect(() => {
    const handler = (event) => {
      if (event.code === 'Enter') {
        login(email, password)
      }
    }
    window.addEventListener('keydown', handler)
    return function cleanup() {
      window.removeEventListener('keydown', handler)
    }
  }, [email, password, login])

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem
          xs={12}
          sm={6}
          md={4}
        >
          <form>
            <Card
              login
              className={classes[cardAnimation]}
            >
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="rose"
              >
                <h4 className={classes.cardTitle}>Log in</h4>
              </CardHeader>
              <CardBody>
                <Collapse in={!!error}>
                  <Alert severity="error">{error}</Alert>
                </Collapse>
                <CustomInput
                  labelText="Email..."
                  id="email"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: email,
                    onChange: e => setEmail(e.target.value),
                    disabled: loading,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email className={classes.inputAdornmentIcon}/>
                      </InputAdornment>
                    ),
                  }}
                />
                <CustomInput
                  labelText="Password"
                  id="password"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: password,
                    onChange: e => setPassword(e.target.value),
                    type: 'password',
                    autoComplete: 'off',
                    disabled: loading,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                  }}
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                {
                  !loading &&
                  <Button
                    onClick={() => login(email, password)}
                    disabled={loading}
                    color="rose"
                    simple
                    size="lg"
                    block
                  >
                    {'Let\'s Go'}
                  </Button>
                }
                {loading && <CircularProgress style={{ color: roseColor[0] }} color={'inherit'} size={24}/>}
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  )
}
