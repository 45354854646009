import axios from "axios";
import User from "./User";
import getApiUrl from "utils/getApiUrl";

const ACCOUNT_URL = `${getApiUrl()}/internal/account`;
const PASSWORD_RESET_URL = ACCOUNT_URL;
const INVOICES_URL = `${ACCOUNT_URL}/invoices`;

export default class Account extends User {
  constructor(data) {
    super(data);
  }

  async update({ planId, firstName, lastName, email, company, phoneNumber }) {
    return axios
      .patch(ACCOUNT_URL, {
        plan_id: planId,
        first_name: firstName,
        last_name: lastName,
        phone_number: phoneNumber,
        email,
        company,
      })
      .then((response) => response.data)
      .then((data) => (this.rawData = data.data));
  }

  static async getAccount() {
    return axios
      .get(ACCOUNT_URL)
      .then((response) => response.data)
      .then((data) => new Account(data.data));
  }

  static async getInvoices() {
    return axios
      .get(INVOICES_URL)
      .then((response) => response.data)
      .then((data) => data.data);
  }

  static async resetPassword(accessToken, password) {
    return axios.patch(
      PASSWORD_RESET_URL,
      {
        password,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }
}
