import React from "react";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-dashboard-pro-react/views/loginPageStyle";
import CardBody from "../Card/CardBody";

export default function ConsumeCard(props) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  return (
    <Card>
      <CardHeader className={classes.textCenter}>
        <p className={classes.cardTitle}>Used data</p>
      </CardHeader>
      <CardBody>
        <div style={{ display: "flex", "justify-content": "center" }}>
          0.00 / 1.00
          <span style={{ fontWeight: "500", color: "#8e24aae6" }}>GB</span>
        </div>
      </CardBody>
    </Card>
  );
}
