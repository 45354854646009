import { types } from 'store/actions/sessionActions'

export function sessionReducer(state = {}, action) {
  switch (action.type) {
    case types.SET_SESSION:
      return Object.assign({}, state, {
        instance: action.payload.session,
      })
    case types.REFRESH_SESSION:
      state.instance.refreshAccess()
        .then(action.payload.successCallback)
        .catch(action.payload.failCallback)
      return state
    default:
      return state
  }
}
