import axios from 'axios'
import getApiUrl from '../utils/getApiUrl'
import { b64ToUtf8 } from '../utils/base64'

const TOKEN_REGISTER_URL = `${getApiUrl()}/sirene/auth/register`
const TOKEN_URL = `${getApiUrl()}/internal/tokens`

export default class Token {
  #data

  constructor(data) {
    this.#data = data
  }

  get rawData() {
    return this.#data
  }

  get refreshToken() {
    return this.#data.refresh_token
  }

  get payload() {
    const encodedPayload = this.refreshToken.split('.')[1]
    const jsonPayload = b64ToUtf8(encodedPayload)
    return JSON.parse(jsonPayload)
  }

  get subject() {
    return this.payload.sub
  }

  get issuedAt() {
    return this.payload.iat
  }

  get expirationTime() {
    return this.payload.exp
  }

  get audience() {
    if (typeof this.payload.aud === 'string') {
      return [this.payload.aud]
    }
    return this.payload.aud
  }

  static async createToken() {
    return axios.post(TOKEN_REGISTER_URL)
      .then(response => response.data)
      .then(data => new Token(data.data))
  }

  static async getTokens() {
    return axios.get(TOKEN_URL)
      .then(response => response.data)
      .then(data => data.data.map(item => new Token(item)))
  }
}
