/*!

=========================================================
* Material Dashboard PRO React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import { Provider } from "react-redux";
// Layouts
import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
// Style
import "assets/scss/material-dashboard-pro-react.scss?v=1.9.0";
// Classes
import Session from "./classes/Session";
import Account from "./classes/Account";
// Modules
import { refreshSession, setSession } from "store/actions/sessionActions";
import { setAccount } from "./store/actions/accountActions";
import store from "store/store";
import history from "router/history";

// Auto session refresh (access token)
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error.response ? error.response.status : null;
    const originalRequest = error.config;
    const retryCount = originalRequest.retry || 0;

    // If authentication has expired
    if (status === 401 && retryCount < 1) {
      return new Promise((resolve, reject) => {
        // Refresh Session
        store.dispatch(
          refreshSession(
            (session) => {
              // Put new access token in failed request headers
              originalRequest.headers.Authorization = `Bearer ${session.accessToken}`;
              // Retry failed request
              resolve(axios(originalRequest));
            },
            () => reject(error) // Return auth request error if fail
          )
        );
        originalRequest.retry = retryCount + 1;
      });
    } else {
      return Promise.reject(error);
    }
  }
);

Session.restore((session) => {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${session.accessToken}`;
})
  .then((session) => store.dispatch(setSession(session)))
  .then(() => Account.getAccount())
  .then((account) => {
    store.dispatch(setAccount(account));
    history.push("/admin/profile");
  })
  .catch(() => {});

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <Route path="/auth" component={AuthLayout} />
        <Route path="/admin" component={AdminLayout} />
        <Redirect from="/" to="/admin/profile" />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById("root")
);
