import React, { useState } from "react";
import { useSelector } from "react-redux";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import PermIdentity from "@material-ui/icons/PermIdentity";
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";
import Clearfix from "components/Clearfix/Clearfix";
import Card from "components/Card/Card";
import { makeStyles } from "@material-ui/core/styles";
import getAxiosError from "utils/getAxiosError";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles";
import Alert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";

const useStyles = makeStyles(styles);

export default function UserForm() {
  const classes = useStyles();
  const account = useSelector((store) => store.account.instance);

  // Form state
  // const [plan, setPlan] = useState(account?.planId || '')
  const [email, setEmail] = useState(account?.email || "");
  const [firstName, setFirstName] = useState(account?.firstName || "");
  const [lastName, setLastName] = useState(account?.lastName || "");
  const [company, setCompany] = useState(account?.company || "");
  const [phoneNumber, setPhoneNumber] = useState(account?.phoneNumber || "");
  // Other state
  const [success, setSuccess] = useState(null);
  // const [warning, setWarning] = useState(null)
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   if (!account) {
  //     return
  //   }
  //   account.getPlan()
  //     .then(plan => setPlan(plan.name))
  //     .catch(error => setWarning(getAxiosError(error)))
  // }, [account])

  const submit = (event) => {
    event.preventDefault();
    setLoading(true);
    setSuccess(null);
    setError(null);
    account
      .update({
        // plan: undefined,
        firstName,
        lastName,
        email,
        company,
        phoneNumber,
      })
      .then(() => setSuccess("Profile updated"))
      .catch((error) => {
        setError(getAxiosError(error));
      })
      .finally(() => setLoading(false));
  };

  return (
    <Card>
      <CardHeader color="rose" icon>
        <CardIcon color="rose">
          <PermIdentity />
        </CardIcon>
        <h4 className={classes.cardIconTitle}>Edit Profile</h4>
      </CardHeader>
      <form onSubmit={(event) => submit(event)}>
        <CardBody>
          <Collapse in={!!success}>
            <Alert severity="success">{success}</Alert>
          </Collapse>
          {/* <Collapse in={!!warning}>
            <Alert severity="warning">{warning}</Alert>
          </Collapse> */}
          <Collapse in={!!error}>
            <Alert severity="error">{error}</Alert>
          </Collapse>
          <GridContainer>
            {/* <GridItem xs={12} sm={12} md={2}>
              <CustomInput
                labelText="Plan"
                id="plan"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  disabled: true,
                  value: plan,
                  onChange: (event) => setPlan(event.target.value),
                }}
              />
            </GridItem> */}
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="First Name"
                id="first-name"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: firstName,
                  disabled: loading,
                  onChange: (event) => setFirstName(event.target.value),
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Last Name"
                id="last-name"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: lastName,
                  onChange: (event) => setLastName(event.target.value),
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <CustomInput
                labelText="Email address"
                id="email"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: email,
                  type: "email",
                  disabled: loading,
                  onChange: (event) => setEmail(event.target.value),
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <CustomInput
                labelText="Phone number"
                id="phone-number"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: phoneNumber,
                  type: "tel",
                  disabled: loading,
                  onChange: (event) => setPhoneNumber(event.target.value),
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <CustomInput
                labelText="Company"
                id="company"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: company,
                  disabled: loading,
                  onChange: (event) => setCompany(event.target.value),
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer></GridContainer>
          <Button
            type="submit"
            color="rose"
            className={classes.updateProfileButton}
            disabled={loading}
          >
            {loading ? <span>Loading...</span> : <span>Update Profile</span>}
          </Button>
          <Clearfix />
        </CardBody>
      </form>
    </Card>
  );
}
