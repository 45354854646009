export const types = {
  SET_ACCOUNT: 'SET_ACCOUNT',
}

export function setAccount(account) {
  return {
    type: types.SET_ACCOUNT,
    payload: {
      account,
    },
  }
}
