import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Collapse from "@material-ui/core/Collapse";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

import { roseColor } from "assets/jss/material-dashboard-pro-react.js";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

import Account from "../../classes/Account";

const useStyles = makeStyles(styles);

export default function LoginPage(props) {
  const history = useHistory();
  const classes = useStyles();

  const urlParams = queryString.parse(props.location.search);

  const [cardAnimation, setCardAnimation] = useState("cardHidden");
  const [token, setToken] = useState(urlParams.token || "");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [finish, setFinish] = useState(false);

  useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });

  useEffect(() => {
    const handler = (event) => {
      if (event.code === "Enter") {
        resetPassword(password1, password2);
      }
    };
    window.addEventListener("keydown", handler);
    return function cleanup() {
      window.removeEventListener("keydown", handler);
    };
  }, [password1, password2]);

  useEffect(() => {
    setError(null);
    if (password1 !== password2) {
      setError("Passwords must match");
    }
  }, [password1, password2]);

  const resetPassword = (password1, password2) => {
    setError(null);
    setLoading(true);
    Account.resetPassword(token, password1)
      .then(() => {
        setFinish(true);
      })
      .catch((error) => {
        console.error(error);
        if (error.response) {
          setError(error.response.data.message);
        } else {
          setError(error.message);
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form>
            <Card login className={classes[cardAnimation]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="rose"
              >
                <h4 className={classes.cardTitle}>Password Reset</h4>
              </CardHeader>
              {finish ? (
                <Collapse in={true} style={{ margin: "20px 0" }}>
                  <Alert severity="success">Password change successfully</Alert>
                </Collapse>
              ) : token.length === 0 ? (
                <Collapse in={true} style={{ margin: "20px 0" }}>
                  <Alert severity="error">No token provided</Alert>
                </Collapse>
              ) : (
                <CardBody>
                  <Collapse in={!!error}>
                    <Alert severity="error">{error}</Alert>
                  </Collapse>
                  <CustomInput
                    labelText="Password"
                    id="password1"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: password1,
                      onChange: (e) => setPassword1(e.target.value),
                      type: "password",
                      autoComplete: "off",
                      disabled: loading,
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputAdornmentIcon}>
                            lock_outline
                          </Icon>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <CustomInput
                    labelText="Password Confirmation"
                    id="password2"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: password2,
                      onChange: (e) => setPassword2(e.target.value),
                      type: "password",
                      autoComplete: "off",
                      disabled: loading,
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputAdornmentIcon}>
                            lock_outline
                          </Icon>
                        </InputAdornment>
                      ),
                    }}
                  />
                </CardBody>
              )}
              {!finish && token.length ? (
                <CardFooter className={classes.justifyContentCenter}>
                  {!loading && (
                    <Button
                      onClick={() => resetPassword(password1, password1)}
                      disabled={loading}
                      color="rose"
                      simple
                      size="lg"
                      block
                    >
                      {"Let's Go"}
                    </Button>
                  )}
                  {loading && (
                    <CircularProgress
                      style={{ color: roseColor[0] }}
                      color={"inherit"}
                      size={24}
                    />
                  )}
                </CardFooter>
              ) : (
                <CardFooter>
                  <Button
                    onClick={() => history.push("auth/login-page")}
                    color="rose"
                    simple
                    size="lg"
                    block
                  >
                    {"Go back to login"}
                  </Button>
                </CardFooter>
              )}
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
