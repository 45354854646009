import axios from "axios";
import getApiUrl from "utils/getApiUrl";

const USERS_URL = `${getApiUrl()}/internal/users`;
const PLANS_URL = `${getApiUrl()}/internal/plans`;
const ACCOUNT_URL = `${getApiUrl()}/internal/account`;

export default class User {
  #data;

  constructor(data) {
    this.#data = data;
  }

  set rawData(data) {
    this.#data = data;
  }

  get rawData() {
    return this.#data;
  }

  get firstName() {
    return this.#data.first_name;
  }

  get lastName() {
    return this.#data.last_name;
  }

  get email() {
    return this.#data.email;
  }

  get company() {
    return this.#data.company;
  }

  get phoneNumber() {
    return this.#data.phone_number;
  }

  get planId() {
    return this.#data.plan_id;
  }

  get isAdmin() {
    return this.#data.admin === 1;
  }

  get comment() {
    return this.#data.comment;
  }

  async getPlan() {
    return axios
      .get(`${PLANS_URL}/${this.planId}`)
      .then((response) => response.data)
      .then((data) => data.data);
  }

  static async getUsers(limit = 10, offset = 0, { onlyAdmin = false } = {}) {
    return axios
      .get(USERS_URL, {
        params: {
          limit,
          offset,
          onlyAdmin,
        },
      })
      .then((response) => response.data)
      .then((data) => data.data.map((userData) => new User(userData)));
  }
  static async subscribe(plan_id, email, client_id) {
    return axios
      .post(`${ACCOUNT_URL}/subscribe`, {
        plan_id,
        email,
        client_id,
      })
      .then((response) => response.data);
  }
}
