import Dashboard from "views/Dashboard/Dashboard.js";
import DashboardEtlSpark from "views/DashboardETL/DashboardEtlSpark";
import Profile from "views/Pages/ProfilePage.js";
import AdminUsers from "views/admin/Users/Users";
import LoginPage from "views/Pages/LoginPage.js";
import ResetPasswordPage from "views/Pages/ResetPasswordPage";
// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import ReceiptIcon from "@material-ui/icons/Receipt";
import PeopleIcon from "@material-ui/icons/People";
import Invoices from "views/Invoices/Invoices";

var dashRoutes = [
  {
    path: "/api-company",
    name: "Api Company Dashboard",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/etl-spark",
    name: "ETL Spark Dashboard",
    icon: DashboardIcon,
    component: DashboardEtlSpark,
    layout: "/admin",
  },
  {
    path: "/profile",
    name: "Profile",
    icon: AccountBoxIcon,
    component: Profile,
    layout: "/admin",
  },
  {
    path: "/invoices",
    name: "Invoices",
    icon: ReceiptIcon,
    component: Invoices,
    layout: "/admin",
  },
  {
    path: "/login-page",
    name: "Login Page",
    mini: "L",
    component: LoginPage,
    layout: "/auth",
    hide: true,
  },
  {
    path: "/users",
    name: "Users",
    icon: PeopleIcon,
    component: AdminUsers,
    layout: "/admin",
    admin: true,
  },
  {
    path: "/password-reset",
    name: "Password reset",
    component: ResetPasswordPage,
    layout: "/auth",
    hide: true,
  },
];
export default dashRoutes;
