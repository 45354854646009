import React, { useEffect, useState } from 'react'
import Card from '../Card/Card'
import CardHeader from '../Card/CardHeader'
import CardFooter from '../Card/CardFooter'
import { makeStyles } from '@material-ui/core/styles'
import styles from '../../assets/jss/material-dashboard-pro-react/views/loginPageStyle'
import CardBody from '../Card/CardBody'
import RegularButton from '../CustomButtons/Button'
import Token from '../../classes/Token'
import Collapse from '@material-ui/core/Collapse'
import Alert from '@material-ui/lab/Alert'
import { CircularProgress, TextField } from '@material-ui/core'
import { DateRange } from '@material-ui/icons'
import copy from 'copy-to-clipboard';
import Snackbar from '@material-ui/core/Snackbar'

export default function TokenCard(props) {
  const useStyles = makeStyles(styles)
  const classes = useStyles()
  const [tokens, setTokens] = useState([])
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [snack, setSnack] = useState(false)
  const getToken = () => {
    setLoading(true)
    Token.getTokens()
      .then((tokens) => setTokens(tokens))
      .catch((e) => setError(e.message || e.response.data.message))
      .finally(() => setLoading(false))
  }
  const createToken = () => {
    Token.createToken()
      .then((token) => setTokens([token]))
      .catch((e) => setError(e.message || e.response.data.message))
  }
  useEffect(() => {getToken()}, [])

  const onInputClick = (e) => {
    e.target.select()
    copy(e.target.value)
    setSnack(true)
  }
  return (
    <Card>
      <CardHeader className={classes.textCenter}>
        <p className={classes.cardTitle}>Your API Token</p>
      </CardHeader>
      <Collapse in={!!error}>
        <Alert severity="error">{error}</Alert>
      </Collapse>
      <CardBody >
        <div style={{ display: 'flex', "justify-content": 'center' }}>
          {loading ?
            <CircularProgress/>
            : !tokens.length ?
              <RegularButton onClick={createToken} color={'success'}>Generate token</RegularButton>
              : <TextField onClick={onInputClick} readOnly value={tokens[0].refreshToken} variant="outlined" style={{ width: '100%' }}/>

          }
          <Snackbar place={'bl'} open={snack} autoHideDuration={3000} onClose={()=>setSnack(false)}>
            <Alert severity="success" onClose={()=>setSnack(false)}>
              Token copied !
            </Alert>
          </Snackbar>
        </div>
      </CardBody>
      {
        tokens.length > 0 &&
        <CardFooter stats>
          <div className={classes.stats}>
            <DateRange/>
            <span>Expires at: </span>
            <span>
            {new Date(tokens[0].expirationTime * 1000).toLocaleString()}
            </span>
          </div>
        </CardFooter>
      }

    </Card>
  )
};
