import React, { useEffect, useState } from "react";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import CardHeader from "components/Card/CardHeader";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import { makeStyles } from "@material-ui/core";
import { PermIdentity } from "@material-ui/icons";
import CardIcon from "components/Card/CardIcon";

import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles";
import Account from "classes/Account";

export default function Invoices() {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    getInvoices();
  }, []);

  async function getInvoices() {
    try {
      const invoicesList = await Account.getInvoices();
      setInvoices(invoicesList);
    } catch (error) {
      return console.log(error.message);
    }
  }

  console.log(invoices);

  function getDateFormat(dateNumber) {
    const newDate = new Date(dateNumber);
    const year = newDate.getFullYear();
    let month = newDate.getMonth();
    if (month < 10) {
      month = `0${month}`;
    }
    let date = newDate.getDate();
    if (date < 10) {
      date = `0${date}`;
    }
    return `${year}:${month}:${date}`;
  }

  const tableData = invoices
    .sort((a, b) => (a.start_date > b.start_date ? -1 : 1))
    .map((invoice) => {
      return [
        getDateFormat(invoice.start_date),
        getDateFormat(invoice.end_date),
        invoice.description,
        JSON.stringify(invoice.details),
        `${invoice.total} €`,
        invoice.status,
      ];
    });

  return (
    <GridContainer>
      <GridItem sm={12} md={12} lg={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <PermIdentity />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Invoices</h4>
          </CardHeader>
          <CardBody>
            <Table
              tableHeaderColor="primary"
              tableHead={[
                "Start date",
                "End date",
                "Description",
                "Details",
                "Total",
                "Status",
              ]}
              tableData={tableData}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
